
import { PaginationData, request } from '@/util/request';

export async function getPointsLog(register_id: number, current: number) {
    const url = `/boss/user/points/log`
    const res = await request.get<PaginationData<any>>(url, {
        params: {
            register_id,
            current: current || 1
        }
    })
    if (res.status == 200) {
        return res.data
    }
    return {
        current: 1,
        limit: 20,
        detail: [],
        total: 0
    }
}