
import PointsForRecord from './components/PointsForRecord.vue';
import PointsDetail from './components/PointsDetail.vue';

import CustomModal from '@/component/custom-modal.vue';
const dateFormat = require('bmh/date-format');
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import {
  PhoneLog,
  getPhonelog,
  getCollectList,
  getCustomerAddress,
  getCustomerDetail,
  getAfterServiceHistory,
  getOrderStatistics,
  getInvoiceHistory,
  getPurchaseHistory,
  initAfterServiceHistory,
  initCollectData,
  initCustomerData,
  initCustomerAddress,
  initInvoiceHistory,
  initOrderStatistics,
  initPruchaseHistory,
  AfterServiceHistoryData,
  CollectData,
  CustomerAddress,
  CustomerDetailData,
  InvoiceHistory,
  OrderStatisticsData,
  PaginationData,
  PurchaseHistory,
  putProfile,
  PutProfileParams,
  getCoupon,
  initCouponData,
  getCustomerTags,
  editManualTags,
} from '@/api/operation/customer';
import { formatDate } from '@/util/global';
enum HANDLE_TYPE {
  'address' = 0,
  'purchase_history',
  'invoice',
  'collect',
  'AfterService',
  'coupon',
}
const REASON_FOR_THE_CREDIT = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '商品质量有问题',
  },
  {
    id: 2,
    value: '漏发/错发',
  },
  {
    id: 3,
    value: '不想要了',
  },
  {
    id: 4,
    value: '其他原因',
  },
];
@Component({
  components: {
    CustomModal,
    PointsDetail,
    PointsForRecord,
  },
})
export default class CustomerDetail extends Vue {
  tagSelect: boolean = false; // 标签是否选中
  isShowEdit: boolean = false; // 标签编辑框
  manual_tags_list: any = []; // 手动标签列表
  selectedTags: any = []; // 选中的手动标签
  manual_tag: any = []; // 手动标签
  auto_tag: any = []; // 自动标签
  isShowPhoneLog: boolean = false;
  customer_phone_log: PhoneLog[] = [];
  customer_address_data: PaginationData<CustomerAddress> = initCustomerAddress(); // 用户收货地址数据
  customer_after_service_data: PaginationData<AfterServiceHistoryData> = initAfterServiceHistory(); // 用户售后记录
  customer_collect_data: PaginationData<CollectData> = initCollectData(); // 用户收藏数据
  customer_detail_data: CustomerDetailData = initCustomerData(); // 用户基础信息
  customer_invoice_data: PaginationData<InvoiceHistory> = initInvoiceHistory(); // 客户开票记录
  customer_purchase_history_data: PaginationData<PurchaseHistory> = initPruchaseHistory(); // 用户购买记录
  is_loading: boolean = false;
  order_statistics_data: OrderStatisticsData = initOrderStatistics(); // 订单统计数据
  register_id: number = 0;
  visible_vip_modal: boolean = false;
  form: PutProfileParams = {
    nickname: '',
    wechat_number: '',
    sex: 1,
    age: 1,
    profession: '',
    description: '',
  };
  customer_coupon_data: PaginationData<any> = initCouponData();
  get handleType() {
    return HANDLE_TYPE;
  }
  get reasonForTheCredit() {
    return REASON_FOR_THE_CREDIT;
  }
  /**
   * 标签编辑框
   */
  editTag() {
    this.isShowEdit = true;
    this.selectedTags = [...this.manual_tag];
  }
  async editTagOk() {
    this.isShowEdit = false;
    let str = '';
    const arr: any = [];
    this.selectedTags.forEach(item => {
      arr.push(item);
    });
    str = arr.join(',');
    const res = await editManualTags(this.register_id, { manual_tag: str });
    if (res && res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.$message.success('编辑成功');
    this.getCustomerInfo();
  }
  editTagCancel() {
    this.isShowEdit = false;
  }
  handleChangeTags(tag, checked) {
    const { selectedTags } = this;
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
    console.log('You are interested in: ', nextSelectedTags);
    this.selectedTags = nextSelectedTags;
  }

  closeVipModal() {
    this.visible_vip_modal = false;
  }
  openVipModal() {
    this.visible_vip_modal = true;
    const { nickname, age, wechat_number, sex, profession, description } =
      this.customer_detail_data;
    this.form = {
      nickname: nickname ? nickname : '',
      age: age ? age : 1,
      wechat_number: wechat_number ? wechat_number : '',
      sex: sex ? sex : 1,
      profession: profession ? profession : '',
      description: description ? description : '',
    };
  }
  async handleOk() {
    const { customer_detail_data, form } = this;
    const res = await putProfile(customer_detail_data.id, form);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.$message.success('编辑成功！');
    this.getCustomerInfo();
    this.visible_vip_modal = false;
  }
  showPhoneLog() {
    this.isShowPhoneLog = true;
  }
  cancel() {
    this.isShowPhoneLog = false;
  }
  dateFormat(time: any) {
    return dateFormat(new Date(time * 1000), '%Y-%m-%d %H:%M:%S');
  }
  paginationOption(data: PaginationData<any>) {
    return {
      current: data.current,
      pageSize: 5,
      showTotal: () => `共${data.total}条, 每页${data.limit}条`,
      total: data.total || 0,
    };
  }

  @changeLoading(['is_loading'])
  async handleTableChange(data: any, type: string) {
    if (type === this.handleType[0]) {
      await this.getCustomerAddress(this.register_id, data.current, 5);
    }
    if (type === this.handleType[1]) {
      await this.getPurchaseHistory(data.current);
    }
    if (type === this.handleType[2]) {
      await this.getInvoiceHistory(data.current);
    }
    if (type == this.handleType[3]) {
      await this.getCollectList(data.current);
    }
    if (type == this.handleType[4]) {
      await this.getAfterHistory(data.current);
    }
    if (type == this.handleType[5]) {
      await this.getCoupon(data.current);
    }
  }

  // 获取用户优惠卷列表
  @changeLoading(['is_loading'])
  async getCoupon(current: number) {
    const res = await getCoupon(this.register_id, current);
    this.customer_coupon_data = initCouponData();
    this.customer_coupon_data = initCouponData(res.data);
  }
  // 获取用户收藏列表
  @changeLoading(['is_loading'])
  async getCollectList(current: number) {
    const res = await getCollectList(this.register_id, current);
    this.customer_collect_data = initCollectData();
    // if (res.status !== 200) {
    //   this.$message.error((res as any).message);
    //   return;
    // }
    this.customer_collect_data = initCollectData(res.data);
  }

  // 获取用户开票记录
  @changeLoading(['is_loading'])
  async getInvoiceHistory(current?: number) {
    const res = await getInvoiceHistory(this.register_id, current);
    this.customer_invoice_data = initInvoiceHistory();
    // if (res.status !== 200) {
    //   this.$message.error((res as any).message);
    //   return;
    // }
    this.customer_invoice_data = initInvoiceHistory(res.data);
  }

  // 获取用户售后记录
  @changeLoading(['is_loading'])
  async getAfterHistory(current?: number) {
    const res = await getAfterServiceHistory(this.register_id, current);
    this.customer_after_service_data = initAfterServiceHistory();
    // if (res.status !== 200) {
    //   this.$message.error((res as any).meesage);
    //   return;
    // }
    this.customer_after_service_data = initAfterServiceHistory(res.data);
  }

  // 获取用户购买记录
  @changeLoading(['is_loading'])
  async getPurchaseHistory(current?: number) {
    const res = await getPurchaseHistory(this.register_id, current);
    this.customer_purchase_history_data = initPruchaseHistory();
    // if (res.status !== 200) {
    //   this.$message.error((res as any).message);
    //   return;
    // }
    this.customer_purchase_history_data = initPruchaseHistory(res.data);
  }

  // 获取用户基本信息
  @changeLoading(['is_loading'])
  async getCustomerInfo() {
    const res = await getCustomerDetail(this.register_id);
    this.customer_detail_data = initCustomerData();
    // console.log('res', res);
    this.manual_tag = res.data?.ext?.manual_tag == '' ? [] : res.data?.ext?.manual_tag.split(',');
    this.auto_tag = res.data?.ext?.auto_tag == '' ? [] : res.data?.ext?.auto_tag.split(',');
    // if (res.status !== 200) {
    //   this.$message.error((res as any).message);
    //   return;
    // }
    this.customer_detail_data = initCustomerData(res.data);
    console.log('tag', this.manual_tag);
  }

  // 获取手动标签
  @changeLoading(['is_loading'])
  async getManualTags() {
    const res = await getCustomerTags({ type: 1 });
    console.log(res);
    this.manual_tags_list = res.data?.detail;
    this.selectedTags = [...this.manual_tag];
    console.log('manual_tags_list', this.manual_tags_list);
    console.log('manual_tag', this.manual_tag);
  }
  //获取用户电话变更记录
  @changeLoading(['is_loading'])
  async getphonelog() {
    const param = { register_id: this.register_id };
    const res = await getPhonelog(param);
    this.customer_phone_log = res.data;
  }
  // 获取用户收货地址信息
  @changeLoading(['is_loading'])
  async getCustomerAddress(register_id: number, current: number = 1, limit?: number) {
    const res = await getCustomerAddress(register_id, current, limit);
    this.customer_address_data = initCustomerAddress();
    // if (res.status !== 200) {
    //   this.$message.error((res as any).message);
    //   return;
    // }
    this.customer_address_data = initCustomerAddress(res.data);
  }

  // 获取订单统计数据
  @changeLoading(['is_loading'])
  async getStatistics() {
    const res = await getOrderStatistics(this.register_id);
    this.order_statistics_data = initOrderStatistics();
    // if (res.status !== 200) {
    //   this.$message.error((res as any).message);
    //   return;
    // }
    this.order_statistics_data = initOrderStatistics(res.data);
  }
  @changeLoading(['is_loading'])
  async created() {
    this.register_id = +this.$route.query.id;
    await this.getCustomerInfo();
    await this.getCustomerAddress(this.register_id);
    await this.getStatistics();
    await this.getPurchaseHistory();
    await this.getAfterHistory();
    await this.getInvoiceHistory();
    await this.getCollectList(1);
    await this.getphonelog();
    await this.getCoupon(1);
    await this.getManualTags();
  }
}
