import { render, staticRenderFns } from "./customer-detail.vue?vue&type=template&id=e475cc64&scoped=true"
import script from "./customer-detail.vue?vue&type=script&lang=ts"
export * from "./customer-detail.vue?vue&type=script&lang=ts"
import style0 from "./customer-detail.vue?vue&type=style&index=0&id=e475cc64&prod&lang=stylus&module=true"
import style1 from "./customer-detail.vue?vue&type=style&index=1&id=e475cc64&prod&lang=less&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "e475cc64",
  null
  
)

export default component.exports